// extracted by mini-css-extract-plugin
export var advancedValue = "LicenseCard-module--advancedValue--de98d";
export var badge = "LicenseCard-module--badge--e5939";
export var contactBtn = "LicenseCard-module--contactBtn--ce565";
export var educationValue = "LicenseCard-module--educationValue--87d11";
export var enterprizeValue = "LicenseCard-module--enterprizeValue--f393d";
export var libraryIcon = "LicenseCard-module--library-icon--fee8a";
export var licenseCard = "LicenseCard-module--licenseCard--03575";
export var liteOn = "LicenseCard-module--liteOn--2b675";
export var moduleItem = "LicenseCard-module--moduleItem--3db8c";
export var modules = "LicenseCard-module--modules--a9ebc";