import React, { useState } from "react";
import * as styles from "./LicenseCards.module.scss";
import { businessData, data } from "./data";
import LicenseCard from "../../molecules/LicenseCard/LicenseCard";
export default function LicenseCards() {
  const [tabs, setTabs] = useState("Individuals");
  const [toggle, setToggle] = useState("Regular");
  return (
    <div className={styles.licenseCards}>
      <h1>Licenses</h1>
      <div className={styles.tabs}>
        <button
          onClick={() => setTabs("Individuals")}
          className={tabs === "Individuals" ? styles.active : ""}
        >
          Individuals
        </button>
        <button
          onClick={() => setTabs("Business")}
          className={tabs === "Business" ? styles.active : ""}
        >
          Business
        </button>
      </div>
      <div className={styles.toggle}>
        <p className={`${toggle === "Regular" ? styles.active : ``}`}>
          Regular
        </p>
        <div
          onClick={() => setToggle(toggle === "Lite" ? "Regular" : "Lite")}
          className={`${styles.toggleSwitch} ${
            toggle === "Lite" ? styles.toggled : ``
          }`}
        ></div>
        <p className={`${toggle === "Lite" ? styles.active : ``}`}>Lite</p>
      </div>
      <div
        className={`${styles.flexItems} ${
          tabs === "Business" && styles.businessData
        }`}
      >
        {tabs === "Individuals"
          ? data.map((license, i) => <LicenseCard
          toggle={toggle}
           key={i} {...license} />)
          : businessData.map((license, i) => (
              <LicenseCard key={i}
              toggle={toggle}
               {...license} />
            ))}
      </div>
    </div>
  );
}
