import React from "react";
import Library from "../../../svg/library-icon.svg";
import Studio from "../../../svg/studio-icon.svg";
import Connect from "../../../images/connectIcon.svg";
import MPublisher from "../../../svg/publisherlogo.svg";
import WizrX from "../../../svg/wizrx.svg";
import Chat from "../../../svg/chatlogo.svg";
import Wizrup from "../../../svg/wizrup.svg";

export const data = [
  {
    modules: [
      {
        name:"library",
        icon: <Library />,
      },
      {
        name:"studio",
        icon: <Studio />,
      },
      {
        name:"connect",
        icon: <Connect />,
      },
      {
        name:"publisher",
        icon: <MPublisher />,
      },

    ],
    title: "All-in-one",
    monthly: {
      lite: 22,
      regular: 45,
    },
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c7751997afba524f5b1e70",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c774fd97afba524f5b1e3c",
    description:
      "Get all mw personal products including Library, studio, and wizr connect.",
    value: "bestValue",
  },
  {
    modules: [
      {
        name:"library",
        icon: <Library />,
      },
      {
        name:"studio",
        icon: <Studio />,
      },
    ],
    title: "Library & Studio",
    monthly: {
      lite: 15,
      regular: 30,
    },
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c7757697afba524f5b1edb",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c7755097afba524f5b1ea5",
    description:
      "Upload, Organize and present documents, images, videos and other files.",
  },
  {
    modules: [
      {
        name:"connect",
        icon: <Connect />,
      }
    ],
    title: "Connect",
    monthly: {
      lite: 7,
      regular: 10,
    },
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c775e297afba524f5b1f4a",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c775a797afba524f5b1f12",
    description:
      "Get everything library offers with reduced storage, and a few features locked.",
  },
  {
    modules: [{
      name:"mpublisher",
      icon: <MPublisher />,
    }],
    title: "MPublisher",
    monthly: {
      lite: 6,
      regular: 10,
    },
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c776a297afba524f5b1fbd",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c7768097afba524f5b1f83",
    description:
      "Upload, Organize and present documents, images, videos and other files.",
  },
];

export const businessData = [
  {
    modules: [
    {
      name:"library",
      icon: <Library />,
      lite: 22,
      regular: 45,
    },
    {
      name:"studio",
      icon: <Studio />,
      lite: 0,
      regular: 0,
    },
    {
      name:"chat",
      icon: <Chat />,
      lite: 0,
      regular: 0,
    },
    {
      name:"connect",
      icon: <Connect />,
      lite: 7,
      regular: 10,
    },
    {
      name:"wizrx",
      icon: <WizrX />,
      lite: 6,
      regular: 10,
    },
    {
      name:"publisher",
      icon: <MPublisher />,
      lite: 6,
      regular: 10,
    },
    {
      name:"wizrup",
      icon: <Wizrup />,
      lite: 0,
      regular: 10,
    },
    ],
    title: "Enterprize",
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c776ff97afba524f5b2034",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c776d897afba524f5b1ff8",
    monthly: {
      lite: 34,
      regular: 70,
    },
    description:
      "Get all mw products including Library, studio, and wizr connect.",
    value: "enterprizeValue",
  },
  {
    modules: [
      {
        name:"library",
        icon: <Library />,
        lite: 22,
        regular: 45,
      },
      {
        name:"studio",
        icon: <Studio />,
        lite: 0,
        regular: 0,
      },
      {
      name:"wizrup",
      icon: <Wizrup />,
      lite: 0,
      regular: 10,
      },
      {
        name:"connect",
        icon: <Connect />,
        lite: 7,
        regular: 10,
      },
      {
        name:"publisher",
        icon: <MPublisher />,
        lite: 6,
        regular: 10,
      },
      {
        name:"chat",
        icon: <Chat />,
        lite: 0,
        regular: 0,
      },
    ],
    title: "Education Plan",
    monthly: {
      lite: 28,
      regular: 60,
    },
    liteLink:"https://accounts.masterwizr.com/campaign/campaign_66c7774997afba524f5b20b4",
    regularLink:"https://accounts.masterwizr.com/campaign/campaign_66c7772c97afba524f5b2076",
    description:
      "Get all mw products including Library, studio, and wizr connect.",
    value: "educationValue",
  },
  {
    modules: [
      {
        name:"library",
        icon: <Library />,
      },
      {
        name:"wizrup",
        icon: <Wizrup />,
      },
      {
        name:"connect",
        icon: <Connect />,
      },
      {
        name:"publisher",
        icon: <MPublisher />,
      },
    ],
    title: "Advanced",
    description:
      "No plan meets your needs? Contact us and we shall craft you a custom license",
    value: "advancedValue",
  },
];
