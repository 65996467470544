import React from "react";
import * as styles from "./LicenseCard.module.scss";
export default function LicenseCard({
  modules = [],
  title,
  monthly,
  description,
  value,
  toggle,
  business,
  liteLink,
  regularLink,
}) {
  const calculatePrice = () => {
    if (business) {
      const monthlyTotal = modules.reduce((total, module) => {
        const modulePrice = module[toggle?.toLowerCase()] || 0; 
        return total + modulePrice;
      }, 0);
      return monthlyTotal * 10;
    } else {
      return toggle === "Lite" ? monthly?.lite || 0 : monthly?.regular || 0;
    }
  };
  
  const totalPrice = calculatePrice();
  return (
    <div className={`${styles.licenseCard} ${styles[value]} ${toggle==="Lite" && styles.liteOn}`}>
      {(value === "bestValue" || value === "enterprizeValue") && (
        <div className={styles.badge}>Best Value</div>
      )}
      <div className={styles.modules}>
        {modules &&
          modules.length > 0 &&
          modules.map((module, i) => (
            <div key={i} className={styles.moduleItem}>
              {module?.icon}
            </div>
          ))}
      </div>
      <h2>{title}</h2>
      {value !== "advancedValue" && (
        <h2>
          <span>From</span>
          <b>
            ${totalPrice} / {business ? "yr" : "mo"}
          </b>
        </h2>
      )}
      <p>{description}</p>
      {value === "advancedValue" ? (
        <a 
        className={styles.contactBtn}
        href="mailto:support@masterwizr.com">Contact Us</a>
      ) : (
        <a
        href={
          toggle==="Regular" ? regularLink : liteLink
        }
        target="_blank"
        rel="noreferrer"
        >Try Now</a>
      )}
    </div>
  );
}
